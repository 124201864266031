<template>
  <div class="toggle">
    <input
      class="toggle__inner"
      type="checkbox"
      :checked="checkedValue"
      v-model="model"
    />
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const emit = defineEmits(["change"]);

const model = defineModel();

const props = defineProps({
  checked: {
    type: [Boolean, String],
  },
});

watch(model, () => {
  emit("change", model.value);
});

const checkedValue = computed(() => {
  if (props.checked === true || props.checked === "true") {
    return true;
  } else {
    return false;
  }
});
</script>

<style lang="scss">
.toggle__inner {
  position: relative;
  width: 3rem;
  height: 1.5rem;
  -webkit-appearance: none;
  appearance: none;
  background: $color-red;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
}

.toggle__inner::before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: $color-light-gray;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s;
  // border: 2px solid $color-white;
}

.toggle__inner:checked::before {
  transform: translateX(100%);
  // background: #fff;
}

.toggle__inner:checked {
  background: $color-green;
}
</style>
