<template>
  <div
    v-if="visible"
    @click="close()"
    class="notification__message"
    :class="`notification-color-${notification.type}`"
  >
    <div class="notification__icon">
      <div :class="`icon-${icon}`"></div>
    </div>
    <div class="notification__item" @click="close()">
      <span v-if="notification.title">{{ notification.title }}</span>
      {{ notification.message }}
    </div>
  </div>
</template>

<script setup>
const app = useNuxtApp();

const emit = defineEmits(["destroy"]);

const props = defineProps({
  notification: {
    type: Object,
  },
});

const icon = computed(() => {
  if (props.notification.icon) {
    return props.notification.icon;
  } else if (props.notification.type === "success") {
    return "check";
  } else if (props.notification.type === "error") {
    return "cross";
  } else {
    return "info";
  }
});

let visible = ref(false);

const close = () => {
  visible.value = false;
  emit("destroy", props.notification.id);
};

onMounted(() => {
  visible.value = true;
  setTimeout(() => {
    close();
  }, 5000);
});
</script>

<style lang="scss">
.notification__message {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $spacing-xs;
  background-color: $color-light-gray;
  margin-bottom: $spacing-xs;
  border-radius: $radius-small;
  padding: $spacing-xs;
}

.notification__icon {
  background: $color-white;
  border-radius: 50%;
  width: 3rem;
  flex: 0 0 3rem;
  height: 3rem;
  position: relative;
  margin-right: 0.5rem;
  color: $color-black;

  & > div {
    @include absolute-center;
  }
}

.notification__item {
  span {
    font-weight: bold;
    display: block;
  }
}

.notification-color-success {
  background-color: $color-green;
  color: $color-white;
}
.notification-color-danger,
.notification-color-error {
  background: $color-red;
  color: $color-white;
}

.notification-color-info {
  background-color: $color-blue;
  color: $color-white;
}
</style>
