<template>
  <div
    class="cart-icon"
    @click="refreshCart(), toggleSidebar(true)"
    @keyup.enter="refreshCart(), toggleSidebar(true)"
    tabindex="0"
  >
    <div class="cart-icon__svg icon-cart"></div>
    <div
      class="cart-icon__count"
      :class="{ 'cart-icon__count--lg': count > 10 }"
      v-if="count > 0"
    >
      <div class="cart-icon__count-inner">
        <span>
          {{ count > 10 ? "10+" : count }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const store = useDefaultStore();
const state = useStates();
const shopwareStore = useShopwareStore();

const { count, refreshCart } = useCart();

const toggleSidebar = (value: boolean) => {
  state.setCartOpen(value);
};

onMounted(() => {});
</script>

<style lang="scss">
.cart__icon {
  position: relative;
}

.cart-icon__svg {
  font-size: $text-2xl;
}

.cart-icon__count {
  position: absolute;
  top: -0.375rem;
  right: -0.7rem;
  font-size: $text-xs;
  background: $color-red;
  border-radius: $radius-xs;
  color: $color-white;
  width: 1rem;
  height: 1rem;

  &--lg {
    width: 1.25rem;
    // height: 1.25rem;
  }
}

.cart-icon__count-inner {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
</style>
